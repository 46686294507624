import React from 'react'


function Page(props) {
  
  if(typeof window !== 'undefined') {
    window.location.replace('https://news.1st.com/blog/preakness-stakes-picks')
  }

  return (
    <div>
      .
    </div>
  )
}

export default Page